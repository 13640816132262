import React, { useEffect, useState } from 'react';
import '../App.css';

function ResimPage() {
  const [kisiler, setKisiler] = useState([]);

  useEffect(() => {


    
    const fetchData = async () => {
      try {
        const response = await fetch('https://adf2024log.eventmagix.net/api/getlastentry');
        const data = await response.json();

        setKisiler(data);
      } catch (error) {
        console.error('Veri alınamadı:', error);
      }
    };

    const interval = setInterval(fetchData, 1000); 
    fetchData(); 
    return () => clearInterval(interval); 
  }, []);

  return (
<div className="App">
  <div className="container-fluid">
    <div className="row">
      {kisiler.length > 0 ? (
        kisiler.map((kisi, index) => (
          <div key={index}  className={`col-2 border turn-${kisi.TurnId}`} style={{ opacity: index >= 12 ? 0.4 : 1 }}>
            <h2>Turnike No: {kisi.TurnId}</h2>
            <>
              <img style={{ width: "65%" }} src={kisi.Image} alt={kisi.FullName} />
              <h3>{kisi.FullName} ({kisi.Hour})</h3>
            </>
          </div>
        ))
      ) : (
        Array.from({ length: 24 }).map((_, index) => (
          <div key={index} style={{animation:"none", opacity: index >= 12 ? 0.4 : 1 }} className="col-2 border">
            <h2>Turnike No: No Data</h2>
            <>
              <img style={{ width: "65%" }} src={"https://registration.antalyadf.org/content/none.jpg"} alt={""} />
              <h3>{"Name Lastname"} ({"00:00:00"})</h3>
            </>
          </div>
        ))
      )}
    </div>
  </div>
</div>

  );
}

export default ResimPage;
